import user_manual from './../../static/docs/user_manual.pdf'
import functional_characteristics from './../../static/docs/functional_characteristics_of_the_software.pdf'
import software_cost from './../../static/docs/software_cost.pdf'
import life_cycle_description from './../../static/docs/life_cycle_description.pdf'
import rospatent from './../../static/docs/rospatent.pdf'

export const DocumentsFiles = [
  {
    title: 'Руководство пользователя',
    url: user_manual,
  },
  {
    title: 'Функциональные характеристики ПО',
    url: functional_characteristics,
  },
  {
    title: 'Описание жизненного цикла',
    url: life_cycle_description,
  },
  {
    title: 'Патент',
    url: rospatent,
  },
  {
    title: 'Стоимость ПО',
    url: software_cost,
  },
]
