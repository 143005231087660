import React from 'react'

import Layout from '../../components/Layout'
import Documents from '../../components/Documents'

const Documentation = () => {
  return (
    <Layout>
      <h1>Документация</h1>
      <Documents />
    </Layout>
  )
}

export default Documentation
