import React from 'react'
import { DocumentsFiles } from './entity'
import * as styles from './documents.module.less'

const Documents = () => {
  return (
    <div className={styles.wrapper}>
      {DocumentsFiles.map((document) => (
        <div className={styles.document} key={document.title}>
          <a href={document.url} target="_blank">
            <span>{document.title}</span>
            <span className={styles.icon}>PDF</span>
          </a>
        </div>
      ))}
    </div>
  )
}

export default Documents
